import React from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const PaymentPage = () => {
  const location = useLocation();
  const { token } = location.state;
  window.dataLayer.push({ ecommerce: null });

  return (
    <>
      {" "}
      <Helmet>
        <title>Online Lostra </title>
        <meta name="description" content="noindex" />
      </Helmet>{" "}
      <div
        style={{
          height: "200vh",
        }}
      >
        <script src="https://www.paytr.com/js/iframeResizer.min.js"></script>
        <iframe
          src={`https://www.paytr.com/odeme/guvenli/${token}`}
          id="paytriframe"
          frameBorder="0"
          scrolling="no"
          style={{ width: "100%", height: "100%" }}
        ></iframe>
        <script>iFrameResize({}, "#paytriframe");</script>
      </div>
    </>
  );
};

export default PaymentPage;
