import React from "react";
import { useState } from "react";
import AcikRiza from "../auth-layouts/components/AcikRiza";
import Policy from "../pages/Policy/Policy";
import SelectCampaignModal from "./SelectCampaignModal";
import cancel from "../../utils/img/icons/cancel-gray.svg";
import SelectInvoiceModal from "./SelectInvoiceModal";
import UselessPhoto from "../../utils/img/OBJECTS.svg";
import { storageHelper } from "../../utils/helpers/StorageHelper";
import baseService from "../../library/network/services/BaseService";

function OrderPreview({
  setPromoValue,
  getUser,
  handleSubmit,
  selectedAddressObj,
  terms,
  userData,
  setUserData,
  formErrorMessage,
  setTerms,
  campaignDiscount,
  policy,
  setPolicy,
  setInvoice,
  userId,
  setPromo,
  promoValue,
  address,
  setSelectedCampaign,
  selectedCampaign,
  setAydinlatma,
  aydınlatma,
  invoice,
  selectedInvoiceObj,
  setSelectedInvoiceObj,
  handleInvoiceClick,
  acikRiza,
  setAcikRiza,
  date,
  setPromoObj,
  time,
  setNote,
}) {
  const [showCampaignModal, setShowCampaignModal] = useState(false);
  const [error, setError] = useState("");
  const [selectCampaign, setSelectCampaign] = useState(false);
  const [selectInvoice, setSelectInvoice] = useState(false);
  const [message, setMessage] = useState("");
  const token = storageHelper.getStoreWithDecryption("token");
  const tokenService = (data) =>
    baseService.post("promocodes/checkValidity", data);

  const handleClick = () => {
    setShowCampaignModal(true);
  };
  const handlePromoCode = async () => {
    try {
      const res = await tokenService({
        code: promoValue,
        userId: userId,
      });

      if (res.data.valid) {
        setPromo(res.data.data);
        setError("");
        if (res.data.discountType === "percentage") {
          setMessage(`${res.data.discount}% indirim uygulandı.`);
        } else {
          setMessage(`${res.data.discount} TL indirim uygulandı.`);
        }
        setPromoValue("");
        setPromo(res.data.id);
        setPromoObj(res.data);
      } else {
        setError(res.data.message);
        setMessage("");
        setPromoValue("");
        setPromo("");
      }
    } catch (err) {
      setMessage("");
      setPromoValue("");
      setPromo("");
      setError("Promosyon kodu bulunamadı.");
    } finally {
    }
  };

  return (
    <>
      <div className="load">
        <div className="plus-div active"> </div>
        <div className="gift-div active"> </div>
      </div>
      {acikRiza && <AcikRiza setOnayMetni={setAcikRiza} />}
      {aydınlatma && (
        <div className="modal-overlay">
          <div className="terms-container position-relative">
            {" "}
            <Policy />
            <button
              onClick={() => setAydinlatma(false)}
              className="btn cancel-button"
            >
              Kapat
            </button>
            <div
              style={{
                top: "25px",
                right: "25px",
                cursor: "pointer",
                position: "absolute",
              }}
            >
              <img
                src={cancel}
                alt="cancel"
                className="cancel-icon"
                onClick={() => setAydinlatma(false)}
              />
            </div>
          </div>
        </div>
      )}
      <SelectCampaignModal
        setSelectedCampaign={setSelectedCampaign}
        selectedCampaign={selectedCampaign}
        campaignDiscount={campaignDiscount}
        setShowCampaignModal={setShowCampaignModal}
      />
      <div className="order-box">
        <h3 className="mb-4">Sipariş Özeti</h3>

        <hr />
        <div className="arrow-div invoice-container ">
          <div onClick={handleInvoiceClick} className="plus-div">
            {selectedInvoiceObj?.firstname && selectInvoice ? (
              <button className="invoice btn btn-order-preview">
                Fatura İsmi: {selectedInvoiceObj.firstname}{" "}
                {selectedInvoiceObj.lastname}
              </button>
            ) : selectedInvoiceObj?.invoiceTitle && selectInvoice ? (
              <button className="invoice btn btn-order-preview">
                Fatura İsmi: {selectedInvoiceObj.invoiceTitle}
              </button>
            ) : (
              <button className="invoice btn btn-order-preview">
                Fatura Seç
              </button>
            )}
          </div>
        </div>
        <hr />
        <table>
          <tbody>
            <tr>
              <td>Alım tarihi:</td>
              {date && (
                <td>
                  {date} / <span>{time}</span>
                </td>
              )}
            </tr>
            <tr>
              <td>Teslimat tarihi: </td>
              <td>5-15 iş günü içerisinde tamamlanacaktır.</td>
            </tr>
          </tbody>
        </table>
        <div className="order-field">
          <h5>Teslim Adresi:</h5>
          <p>{address?.address}</p>
        </div>

        <div className="order-field">
          <h5>Notunuz:</h5>
          <textarea
            onChange={(e) => setNote(e.target.value)}
            name="note"
            id="note"
            maxLength="500"
            cols="30"
            rows="6"
            placeholder="Bizlere iletmek istediğiniz notu bu alana yazabilirsiniz."
          />
        </div>
        <h3 className="mt-5 mb-5">Promosyon Kodu</h3>
        <div className=" my-5">
          <div
            className="input-group"
            style={{
              position: "relative",
            }}
          >
            <input
              style={{
                borderRadius: "15px",
              }}
              type="text"
              onChange={(e) => setPromoValue(e.target.value)}
              id="promoCode"
              className="promo-input"
              value={promoValue}
              placeholder="Promosyon kodu"
            />
            <button
              onClick={handlePromoCode}
              type="button"
              id="promoCode"
              disabled={promoValue.length < 1}
              // disabled
              className="promo-button"
            >
              Uygula
            </button>
          </div>
          <div>
            <h6 className="text-danger my-4">{error}</h6>
          </div>
          {message && (
            <div className="promo-info my-2">
              <p className="text-success">{message}</p>
              <div
                className="img-div"
                onClick={() => {
                  setMessage("");
                  setPromo("");
                }}
              >
                <img src={cancel} alt="" className="cancel" />
              </div>
            </div>
          )}{" "}
          {selectedCampaign && (
            <div className="promo-info">
              <p className="text-success">{selectedCampaign?.title}</p>
              <div
                className="img-div"
                onClick={() => {
                  setSelectedCampaign("");
                }}
              >
                <img src={cancel} alt="" className="cancel" />
              </div>
            </div>
          )}{" "}
        </div>

        <form>
          <div className="register-content">
            <div
              style={{
                // height: "60px",
                display: "flex",
              }}
              className="m-0 form-area terms "
            >
              <input
                onChange={(e) => {
                  setTerms(e.target.checked);
                }}
                checked={terms}
                style={{
                  marginTop: "10px",
                  width: "25px",
                }}
                type="checkbox"
                id="term"
                name="term"
              />
              <label
                htmlFor="term"
                style={{
                  width: "455px",
                }}
              >
                <p
                  className="m-0"
                  style={{
                    fontSize: "12px",
                    color: "#9393AA",
                    width: "100%",
                  }}
                >
                  {" "}
                  <span
                    onClick={() => {
                      setAydinlatma(true);
                    }}
                    className="term-links"
                  >
                    {" "}
                    Kullanım Koşulları’nı ve Aydınlatma Metni’ni
                  </span>{" "}
                  okudum, anladım ve kabul ediyorum.
                </p>
              </label>
            </div>
            <div
              style={{
                display: "flex",
              }}
              className="m-0 form-area terms "
            >
              <input
                onChange={(e) => {
                  setPolicy(e.target.checked);
                }}
                checked={policy}
                style={{
                  marginTop: "10px",

                  width: "25px",
                }}
                type="checkbox"
                id="policy"
                name="policy"
              />
              <label
                htmlFor="policy"
                style={{
                  width: "455px",
                }}
              >
                <p
                  className="m-0"
                  style={{
                    fontSize: "12px",
                    color: "#9393AA",
                    width: "100%",
                  }}
                >
                  Kişisel verilerimin işlenmesine ilişkin{" "}
                  <span
                    onClick={() => {
                      setAcikRiza(true);
                    }}
                    className="term-links"
                  >
                    {" "}
                    Açık Rıza Beyanı’nı
                  </span>{" "}
                  kabul ederim.
                </p>
              </label>
            </div>
          </div>
        </form>
        <p className="error-auth">{formErrorMessage}</p>

        <button
          type="submit"
          disabled={!terms || !policy}
          onClick={handleSubmit}
          className="button button-primary w-100"
        >
          Siparişi Tamamla
        </button>
        <div className=" d-flex flex-column gap-4 mt-4 justify-content-between">
          <h6 className="sub-text-bold lh-base">
            {" "}
            *Ürünleriniz atölyemize ulaştıktan sonra ilk inceleme işlemleri
            ekibimiz tarafından detaylıca yapılacak, gerekli işlemler
            belirlenecek ve sizlere bildirim olarak iletilecektir. Bu adımda
            gerekli işlemleri, tutarları görüntüledikten sonra ödeme
            yapabilirsiniz.
          </h6>
          <span className="sub-text">
            {" "}
            * İki yönlü kurye hizmet bedeli 89.90TL’dir. <br /> *Tüm indirimler
            dahil minimum sipariş tutarı 399.90TL’dir.
          </span>
        </div>
      </div>

      {invoice && (
        <SelectInvoiceModal
          selectInvoice={selectInvoice}
          setSelectInvoice={setSelectInvoice}
          selectedAddressObj={selectedAddressObj}
          setInvoice={setInvoice}
          invoice={invoice}
          selectedInvoiceObj={selectedInvoiceObj}
          setSelectedInvoiceObj={setSelectedInvoiceObj}
          getUser={getUser}
          userData={userData}
          setUserData={setUserData}
          setInvoiceModal={setInvoice}
        />
      )}
    </>
  );
}

export default OrderPreview;
