import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import info from "../../../utils/img/icons/information.svg";
import { Helmet } from "react-helmet-async";
import cancel from "../../../utils/img/icons/remove.png";
import Loader from "../../app-layouts/Loader";
import SuccessInfo from "../../app-layouts/SuccessInfo";
import { ref, getDownloadURL } from "firebase/storage";
import { storage } from "../../../firebase";
import Modal from "../../app-layouts/ConfirmModal";
import {
  confirmOrderService,
  deleteOrderOfferDetail,
  getOrderDetailsById,
  recoverOrderOfferDetail,
} from "../../../library/network/requests/order-offer-details";
import InfoModal from "../../app-layouts/InfoModal";
import { storageHelper } from "../../../utils/helpers/StorageHelper";
import OrderForm from "./OrderForm";

import { getUserById } from "../../../library/network/requests/webusers";

import baseService from "../../../library/network/services/BaseService";

function OrderConfirm() {
  const [targetDate, setTargetDate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [offerId, setOfferId] = useState();
  const [promoCodeData, setPromoCodeData] = useState({});
  const [note, setNote] = useState("");
  const [cancelledOrders, setCancelledOrders] = useState([]);
  const [addCover, setAddCover] = useState([]);
  const [isCargo, setIsCargo] = useState(false);
  const [orderNum, setOrderNum] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState("");
  const [confirmationModal, setConfirmationModal] = useState(false);
  const params = useParams();
  const [offerInformation, setOfferInformation] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [success, setSuccess] = useState(false);
  const [campaignDiscount, setCampaignDiscount] = useState(null);
  const [message, setMessage] = useState("");
  const [offersById, setOffersById] = useState([]);
  const offerDetailByIdService = async (id) =>
    baseService.get(`order-offer-details/${id}`);
  const pricesService = async () => baseService.get("servicecategoryprices");
  const promoService = async (data, id) =>
    baseService.post(`order/addPromo/${id}`, data);
  const promoCheckService = async (data) =>
    baseService.post("promocodes/checkValidity", data);
  const reserveService = async (data) =>
    baseService.post("payment/reserv", data);
  const [totalPrice, setTotalPrice] = useState(0);
  const [servicePrices, setServicePrices] = useState([]);
  const [selectedExtraProducts, setSelectedExtraProducts] = useState([]);
  const [deliveryIds, setDeliveryIds] = useState([]);
  const [mandatoryServices, setMandatoryServices] = useState([]);
  const navigate = useNavigate();
  const [deliveryPrices, setDeliveryPrices] = useState([]);
  const token = storageHelper.getStoreWithDecryption("token");
  const [selectedOptions, setSelectedOptions] = useState({});
  const [optionalSelectedOptions, setOptionalSelectedOptions] = useState([]);
  const [promoValue, setPromoValue] = useState("");
  const [promoObj, setPromoObj] = useState({});
  const [error, setError] = useState("");
  const [promo, setPromo] = useState("");
  const [userId, setUserId] = useState("");
  const user = storageHelper.getStoreWithDecryption("userId");
  const [userDatas, setUserDatas] = useState([]);
  const [change, setChange] = useState("order");
  const [firstOrder, setFirstOrder] = useState(false);
  const [imageUrls, setImageUrls] = useState([]);
  const extraProductsService = async () => baseService.get("extraproducts");
  const userOrderService = async () => baseService.get(`order/webuser/${user}`);
  const [extraProducts, setExtraProducts] = useState([]);
  const deliveryService = async () => baseService.get("deliveryprice");
  const promoStatusService = async (data) =>
    baseService.post("order/status/", data);
  const getDeliveryPrice = async () => {};
  const getUser = async () => {
    if (user) {
      const res = await getUserById(user);
      setUserDatas(res.data);
    }
  };
  const getImageUrl = async (image) => {
    const storageRef = ref(storage, "images/" + image);
    try {
      const url = await getDownloadURL(storageRef);
      if (url) {
        setImageUrls((prev) => ({ ...prev, [image]: url }));
      }
    } catch (error) {
      console.error("Error getting image URL from Firebase Storage:", error);
    }
  };
  const getUserOrders = async () => {
    try {
      const res = await userOrderService();
      const filtered = res.data.filter((order) => order.isServicePaid === true);
      if (filtered.length === 0) {
        setFirstOrder(true);
      } else {
        setFirstOrder(false);
      }
    } catch (error) {}
  };
  const getExtraProducts = async () => {
    try {
      const res = await extraProductsService();
      res.data.forEach((item) => {
        if (item.mainImages) {
          getImageUrl(item.mainImages);
        }
      });
      const filtered = res.data.filter((item) => item.isDeleted === false);
      setExtraProducts(filtered);
    } catch (error) {
      console.log(error);
    }
  };
  const getAllPrices = async () => {
    try {
      const res = await pricesService();
      setServicePrices(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handlePromoCode = async () => {
    try {
      const data = {
        code: promoValue,
        userId: user,
      };
      const res = await promoCheckService(data);
      if (res.data.valid) {
        const data = {
          promoCode: res.data.id,
        };
        await promoService(data, params.id);

        setPromo(res.data.data);
        setError("");
        setPromoObj(res.data);

        if (res.data.discountType === "percentage") {
          setMessage(`${res.data.discount}% indirim uygulandı.`);
        } else {
          setMessage(`${res.data.discount} TL indirim uygulandı.`);
        }
        setPromoValue("");
        setPromo(res.data.id);
      } else {
        setError(res.data.message);
        setMessage("");
        setPromoObj({});
        setPromoValue("");
        setPromo("");
      }
    } catch (err) {
      console.log(err);
      setMessage("");
      setPromoValue("");
      setPromo("");
      setError("Promosyon kodu bulunamadı.");
    }
  };
  const generateUniqueOrderID = () => {
    let randomPart = Math.floor(Math.random() * 90000) + 1000;

    const timestamp = new Date().getTime();

    const millisecondPart = timestamp.toString().slice(-4);

    const orderNumber = `${randomPart}${millisecondPart}`;

    setOrderNum(orderNumber);
  };
  const handleOptionalChange = (event, offerId, options) => {
    const { value } = event.target;
    const optionId = value.split("-")[1];
    const selectedOption = options.find((opt) => opt._id === optionId);
    if (selectedOption) {
      const newPrice = selectedOption.price;
      const previousOption = options.find(
        (opt) => opt._id === optionalSelectedOptions[offerId].split("-")[1]
      );
      const previousPrice = previousOption ? previousOption.price : 0;
      const total = totalPrice - previousPrice + newPrice;
      setTotalPrice(total);
      setOptionalSelectedOptions((prevSelectedOptions) => ({
        ...prevSelectedOptions,
        [offerId]: value,
      }));
    }
  };

  const handleDeliveryOptionChange = (event) => {
    const { name, value } = event.target;

    const id = name;
    let amount = 0;
    if (value.startsWith("premium")) {
      amount = 34;
    } else if (value.startsWith("free")) {
      if (selectedOptions[id] === 34) {
        amount = -34;
      }
    }

    setTotalPrice((prevTotalPrice) => prevTotalPrice + amount);
    setSelectedOptions((prevSelectedOptions) => {
      let optionValue;
      if (value.startsWith("premium")) {
        optionValue = 34;
      } else if (value.startsWith("free")) {
        optionValue = 0;
      } else if (value.startsWith("firstfree")) {
        optionValue = 1;
      }
      return {
        ...prevSelectedOptions,
        [id]: optionValue,
      };
    });
    setDeliveryIds((prevSelectedOptions) => {
      let optionValue;
      if (value.startsWith("premium")) {
        optionValue = 34;
      } else if (value.startsWith("free")) {
        optionValue = 0;
      } else if (value.startsWith("firstfree")) {
        optionValue = 1;
      }
      return {
        ...prevSelectedOptions,
        [id]: optionValue,
      };
    });
  };
  const handleOfferDelete = async () => {
    try {
      setLoading(true);
      const res = await deleteOrderOfferDetail(offerId._id, {
        orderId: params.id,
        type: offerId?.orderDetailId?.serviceCategoryId?.icon,
      });

      if (res.status === 200) {
        getOffersById();
        setDeleteModal(false);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setDeleteModal(false);
    }
  };
  const handleCancelOrder = async () => {
    try {
      const data = {
        statusName: "cancelled",
      };
      await promoStatusService(data);
      navigate("/hesap/siparis");
      setDeleteModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCoverChange = (event, offer) => {
    const isChecked = event.target.checked;
    const currentValue = totalPrice;
    const updatedValue = isChecked
      ? currentValue + 149.9
      : currentValue - 149.9;
    setTotalPrice(updatedValue);
    setAddCover((prevAddCover) => ({
      ...prevAddCover,
      [offer._id]: isChecked,
    }));
  };
  const handleCheckboxChange = (event, offerPrice, offer) => {
    if (event.target.name === "mandatory") {
      return;
    }

    const isChecked = event.target.checked;
    const currentValue = totalPrice;
    const updatedValue = isChecked
      ? currentValue + offerPrice
      : currentValue - offerPrice;

    setTotalPrice(updatedValue);
  };
  const isUserExist = async () => {
    const token = storageHelper.getStoreWithDecryption("token");
    setLoading(true);
    if (token) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  };

  const isAfterTargetDate = (date) => {
    const targetDate = new Date(2024, 10, 22);
    return date > targetDate;
  };

  const getOffersById = async () => {
    const res2 = await deliveryService();
    setDeliveryPrices(res2.data);
    const res = await getOrderDetailsById(params.id);
    const orderDate = new Date(res.data[0].orderId.OrderDate);

    const cancelledOrders = res.data[0].offers.filter(
      (offer) => offer.isCancelled === true
    );
    setCancelledOrders(cancelledOrders);
    const filteredcancelled = res.data[0].offers.filter(
      (offer) => offer.isCancelled === false
    );
    const response = res.data;
    if (response[0]?.orderId?.isConfirmed) {
      navigate("/hesap/siparis");
    }
    response[0]?.pickUpDate === "" ? setIsCargo(false) : setIsCargo(true);

    response[0].offers = filteredcancelled;
    response[0].offers.forEach((offer) => {
      if (offer.mainImages) {
        offer.mainImages.forEach((image) => {
          getImageUrl(image);
        });
      }
      if (offer.optionsExtraImages) {
        offer.optionsExtraImages.forEach((image) => {
          getImageUrl(image);
        });
      }
    });
    response[0].offers.forEach((offer) => {
      if (offer.service.length > 0) {
        offer.service.forEach((service) => {
          if (service?.images?.length > 0) {
            service.images.forEach((image) => {
              getImageUrl(image);
            });
          }
        });
      }
    });
    response[0].offers.forEach((offer) => {
      if (offer?.optionals?.length > 0) {
        offer?.optionals[0]?.images.forEach((image) => {
          getImageUrl(image);
        });
      }
    });

    setPromoCodeData(res.data[0]?.orderId?.promo);
    const targetDate = isAfterTargetDate(orderDate);

    setTargetDate(targetDate);
    let totalPrice = targetDate ? 89.9 : 0;

    response.forEach((obj) => {
      obj.offers.forEach((offer) => {
        offer.service.forEach((service) => {
          if (service.mandatory) {
            totalPrice += service.price;
          }
        });
      });
    });
    response.forEach((obj) => {
      obj.offers.forEach((offer) => {
        offer.service.forEach((service) => {
          if (!service.mandatory) {
            totalPrice += service.price;
          }
        });
      });
    });
    response.forEach((obj) => {
      obj.offers.forEach((offer) => {
        if (offer.optionals.length > 0) {
          totalPrice += offer.optionals[0].price;
          setOptionalSelectedOptions((prevSelectedOptions) => ({
            ...prevSelectedOptions,
            [offer._id]: `${offer._id}-${offer.optionals[0]._id}`,
          }));
        }
      });
    });

    let mandatory = [];
    response.forEach((obj) => {
      obj.offers?.forEach((offer) => {
        offer?.service?.forEach((service, index) => {
          if (service.mandatory) {
            mandatory.push(`${offer._id}-${service._id}-${index}`);
          }
        });
      });
    });
    let services = [];

    response.forEach((obj) => {
      obj.offers?.forEach((offer) => {
        const tamirServices = offer?.service?.filter(
          (serv) => serv.type === "tamir"
        );
        const bakimServices = offer?.service?.filter(
          (serv) => serv.type === "bakim"
        );
        tamirServices?.forEach((service, index) => {
          if (!service.mandatory) {
            services.push(`${offer?._id}-${service?._id}-${index}`);
          }
        });
        bakimServices?.forEach((service, index) => {
          if (!service.mandatory) {
            services.push(`${offer?._id}-${service?._id}-${index}`);
          }
        });
      });
    });
    formik.values.selectedOffers = services;
    setMandatoryServices(mandatory);
    if (
      response[0]?.orderId?.campaignDiscount &&
      response[0]?.orderId?.isCampaignConfirmed
    ) {
      setCampaignDiscount(res.data[0]?.orderId?.campaignDiscount);

      const newPrice =
        totalPrice - res.data[0]?.orderId?.campaignDiscount.discount;
      // setTotalPrice(price);
    } else {
      setCampaignDiscount(null);
    }
    setTotalPrice(totalPrice);

    setOffersById(res.data);
  };
  const generateUserBasketData = async (result) => {
    const basketItems = [];
    for (const item of result) {
      const offerDetails = await offerDetailByIdService(item.productId);
      const product = offerDetails?.data;
      if (product) {
        const totalPrice = item.services.reduce((total, serviceId) => {
          const servicePrice =
            servicePrices.find((s) => s.id === serviceId)?.price || 0;
          return total + servicePrice;
        }, 0);
        basketItems.push([product.name.name, totalPrice.toFixed(2), 1]);

        if (item.deliveryPrice === 34) {
          const premiumIndex = basketItems.findIndex(
            (item) => item[0] === "Premium Teslimat"
          );
          if (premiumIndex !== -1) {
            basketItems[premiumIndex][2] += 1;
            basketItems[premiumIndex][1] += item.deliveryPrice;
          } else {
            basketItems.push(["Premium Teslimat", item.deliveryPrice, 1]);
          }
        }
      }
    }

    if (selectedExtraProducts.length > 0) {
      selectedExtraProducts.forEach((extra) => {
        basketItems.push([
          extra.title + " " + extra.size,
          extra.price * extra.quantity,
          extra.quantity,
        ]);
      });
    }
    if (Object.keys(addCover).length > 0) {
      Object.keys(addCover).forEach((key) => {
        if (addCover[key]) {
          const kalipIndex = basketItems.findIndex(
            (item) => item[0] === "Kalip"
          );
          if (kalipIndex !== -1) {
            basketItems[kalipIndex][2] += 1;
            basketItems[kalipIndex][1] += 149.9;
          } else {
            basketItems.push(["Kalip", 149.9, 1]);
          }
        }
      });
    }
    if (targetDate) {
      basketItems.push(["Teslimat", 89.9, 1]);
    }

    const basket = JSON.stringify(basketItems);

    return basket;
  };
  const minPrice = 399;
  const calculateDiscountAmount = () => {
    let discountAmount = 0;

    if (promoCodeData) {
      discountAmount +=
        promoCodeData.discountType === "percentage"
          ? (totalPrice * promoCodeData.discount) / 100
          : promoCodeData.discount || 0;
    } else if (promoObj?.discountType) {
      discountAmount +=
        promoObj.discountType === "percentage"
          ? (totalPrice * promoObj.discount) / 100
          : promoObj.discount || 0;
    }

    discountAmount += campaignDiscount?.discount || 0;

    return discountAmount.toFixed(2);
  };
  const calculateTotalAmount = () => {
    // Check if there is a discount
    if (promoCodeData) {
      if (promoCodeData?.discountType === "percentage") {
        // Calculate total price with percentage discount
        const discountedPrice =
          totalPrice -
          (totalPrice * promoCodeData?.discount) / 100 -
          (campaignDiscount?.discount || 0);

        return Math.round(
          discountedPrice < 400 ? minPrice * 100 : discountedPrice * 100
        );
      } else {
        // Calculate total price with fixed discount
        const discountedPrice =
          totalPrice -
          (promoCodeData?.discount || 0) -
          (campaignDiscount?.discount || 0);

        return Math.round(
          discountedPrice < 400 ? minPrice * 100 : discountedPrice * 100
        );
      }
    } else if (promoObj?.discountType) {
      // Check if there is a promo with discount
      if (promoObj.discountType === "percentage") {
        // Calculate total price with percentage promo discount
        const discountedPrice =
          totalPrice -
          (totalPrice * promoObj?.discount) / 100 -
          (campaignDiscount?.discount || 0);

        return Math.round(
          discountedPrice < 400 ? minPrice * 100 : discountedPrice * 100
        );
      } else {
        // Calculate total price with fixed promo discount
        const discountedPrice =
          totalPrice -
          (promoObj?.discount || 0) -
          (campaignDiscount?.discount || 0);

        return Math.round(
          discountedPrice < 400 ? minPrice * 100 : discountedPrice * 100
        );
      }
    } else {
      // const deliveryPrice = isCargo
      //   ? deliveryPrices[0]?.deliveryPrice
      //   : deliveryPrices[0]?.couirierPrice;
      // const totalPriceWithdelivery = deliveryPrice + totalPrice;
      const totalPriceWithoutDiscount =
        totalPrice - (campaignDiscount?.discount || 0);

      return Math.round(
        totalPrice <= minPrice
          ? minPrice * 100
          : totalPriceWithoutDiscount * 100
      );
    }
  };

  const formik = useFormik({
    initialValues: {
      selectedOffers: [],
      selectedDelivery: selectedOptions,
    },
    onSubmit: async (values) => {
      // setSuccess(false);
      setLoading(true);
      const obj = values.selectedOffers;
      const groupedObj = {};
      const modifiedDeliveryIds = {};
      const modifiedOptionals = Object.values(optionalSelectedOptions);
      for (const key in deliveryIds) {
        if (deliveryIds.hasOwnProperty(key)) {
          const modifiedKey = key.split("-")[0];
          const value = deliveryIds[key];
          modifiedDeliveryIds[modifiedKey] = value;
        }
      }
      for (let i = 0; i < mandatoryServices.length; i++) {
        const serviceId = mandatoryServices[i];
        const [productId, service, index] = serviceId?.split("-");
        const deliveryPrice = modifiedDeliveryIds[`${productId}`] || 0;
        if (!groupedObj[`${productId}`]) {
          groupedObj[`${productId}`] = {
            productId: productId,
            services: [],
            deliveryPrice: deliveryPrice,
            note: note,
            confirmOrderNumber: orderNum,
            extraProducts: selectedExtraProducts,
            cover: addCover[productId] || false,
          };
        }
        groupedObj[`${productId}`].services.push(service);
      }
      for (let i = 0; i < obj.length; i++) {
        const serviceId = obj[i];
        const [productId, service, index] = serviceId?.split("-");
        const deliveryPrice = modifiedDeliveryIds[`${productId}`] || 0;
        if (!groupedObj[`${productId}`]) {
          groupedObj[`${productId}`] = {
            productId: productId,
            services: [],
            deliveryPrice: deliveryPrice,
            note: note,
            confirmOrderNumber: orderNum,
            extraProducts: selectedExtraProducts,
            cover: addCover[productId] || false,
          };
        }
        groupedObj[`${productId}`].services.push(service);
      }
      for (let i = 0; i < modifiedOptionals.length; i++) {
        const serviceId = modifiedOptionals[i];
        const [productId, service, index] = serviceId?.split("-");
        const deliveryPrice = modifiedDeliveryIds[`${productId}`] || 0;
        if (!groupedObj[`${productId}`]) {
          groupedObj[`${productId}`] = {
            productId: productId,
            services: [],
            deliveryPrice: deliveryPrice,
            note: note,
            confirmOrderNumber: orderNum,
            extraProducts: selectedExtraProducts,
            cover: addCover[productId] || false,
          };
        }
        groupedObj[`${productId}`].services.push(service);
      }

      const result = Object.values(groupedObj);
      const paymentData = {
        email: userDatas?.email || "",
        payment_amount: calculateTotalAmount(),
        merchant_oid: orderNum,
        user_name: `${userDatas?.firstname} ${userDatas?.lastname} ` || "",
        user_address: offersById[0]?.orderId?.address[0]?.address || "",
        user_phone: userDatas?.phone || "",
        merchant_fail_url: `https://onlinelostra.com/payment-failed`,
        merchant_ok_url: `https://onlinelostra.com/thank-you`,
        user_basket: await generateUserBasketData(result),
        user_ip: "127.0.0.1",
      };
      const data = await generateUserBasketData(result);
      const prices = calculateTotalAmount();
      const deliveryPrice = isCargo
        ? deliveryPrices[0]?.deliveryPrice
        : deliveryPrices[0]?.couirierPrice;
      const orderPrice = prices / 100;
      const model = {
        confirmOrder: result,
        totalPrice: orderPrice,
      };

      const res = await confirmOrderService(params.id, model);
      setLoading(false);
      if (res.status === 200) {
        // setSuccess(true);
        const data = {
          paymentData: paymentData,
        };
        const res = await reserveService(data);
        if (res.data.status === "success") {
          const purchaseData = {
            event: "purchase",
            ecommerce: {
              transaction_id: orderNum,
              value: calculateTotalAmount() / 100,
              tax: (calculateTotalAmount() / 100) * 0.2,
              shipping: 0,
              currency: "TRY",
              coupon: promoCodeData?.code || promoObj?.code || "",
              items: [
                {
                  item_id: params.id,
                  promotion_text: campaignDiscount?.title || "",
                  item_name: "Ayakkabi Temizleme Hizmeti",
                  coupon: promoCodeData?.code || promoObj?.code || "",
                  discount: calculateDiscountAmount(),
                  index: 0,
                  location_id: "Online Lostra",
                  price: calculateTotalAmount() / 100,
                  quantity: result.length,
                },
              ],
            },
          };
          storageHelper.setStoreWithEncryption("purchaseData", purchaseData);

          navigate("/payment", { state: { token: res.data.token } });
        }
        setMessage("Siparişiniz onaylanmıştır.");
      }
    },
  });

  const handleRecover = async (id) => {
    try {
      setLoading(true);
      const res = await recoverOrderOfferDetail(id._id, {
        orderId: params.id,
        type: id?.orderDetailId?.serviceCategoryId?.icon,
      });

      if (res.status === 200) {
        getOffersById();
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const guiId = urlParams.get("guiId");
    if (!token && !guiId) {
      navigate("/auth/giris-yap");
    }
    isUserExist();
    setLoading(true);

    if (user && token) {
      Promise.all([
        getAllPrices(),
        getDeliveryPrice(),
        getOffersById(),
        getExtraProducts(),
        getUser(),
        getUserOrders(),
        setSelectedExtraProducts([]),
      ]).then(() => {
        setLoading(false);
      });
    }
  }, [promoObj, token, user]);
  useEffect(() => {
    generateUniqueOrderID();
  }, []);
  return (
    <>
      <Helmet>
        <title>Online Lostra - Sipariş Onayı</title>
        <meta name="description" content="noindex" />
      </Helmet>{" "}
      {loading && <Loader />}
      <section className="page-section left">
        {change === "order" ? (
          <form onSubmit={formik.handleSubmit}>
            <OrderForm
              targetDate={targetDate}
              handleRecover={handleRecover}
              cancelledOrders={cancelledOrders}
              imageUrls={imageUrls}
              firstOrder={firstOrder}
              addCover={addCover}
              handleCoverChange={handleCoverChange}
              setAddCover={setAddCover}
              selectedExtraProducts={selectedExtraProducts}
              setSelectedExtraProducts={setSelectedExtraProducts}
              extraProducts={extraProducts}
              campaignDiscount={campaignDiscount}
              offersById={offersById}
              selectedImageIndex={selectedImageIndex}
              selectedOptions={selectedOptions}
              setConfirmationModal={setConfirmationModal}
              setDeleteModal={setDeleteModal}
              setIsModalOpen={setIsModalOpen}
              setMessage={setMessage}
              setNote={setNote}
              setOfferId={setOfferId}
              setOfferInformation={setOfferInformation}
              setOptionalSelectedOptions={setOptionalSelectedOptions}
              setPromo={setPromo}
              setPromoObj={setPromoObj}
              cancel={cancel}
              setSelectedImageIndex={setSelectedImageIndex}
              isModalOpen={isModalOpen}
              handleCheckboxChange={handleCheckboxChange}
              formik={formik}
              handleOptionalChange={handleOptionalChange}
              info={info}
              handleDeliveryOptionChange={handleDeliveryOptionChange}
              promoObj={promoObj}
              promoCodeData={promoCodeData}
              promoValue={promoValue}
              setPromoValue={setPromoValue}
              handlePromoCode={handlePromoCode}
              error={error}
              promo={promo}
              message={message}
              totalPrice={totalPrice}
              setTotalPrice={setTotalPrice}
              userId={userId}
              setUserId={setUserId}
              note={note}
              change={change}
              setChange={setChange}
              getOffersById={getOffersById}
            />
          </form>
        ) : (
          <div className="form">
            <OrderForm
              targetDate={targetDate}
              handleRecover={handleRecover}
              imageUrls={imageUrls}
              cancelledOrders={cancelledOrders}
              firstOrder={firstOrder}
              addCover={addCover}
              setAddCover={setAddCover}
              selectedExtraProducts={selectedExtraProducts}
              setSelectedExtraProducts={setSelectedExtraProducts}
              extraProducts={extraProducts}
              campaignDiscount={campaignDiscount}
              offersById={offersById}
              selectedImageIndex={selectedImageIndex}
              selectedOptions={selectedOptions}
              setConfirmationModal={setConfirmationModal}
              setDeleteModal={setDeleteModal}
              setIsModalOpen={setIsModalOpen}
              setMessage={setMessage}
              setNote={setNote}
              setOfferId={setOfferId}
              setOfferInformation={setOfferInformation}
              setOptionalSelectedOptions={setOptionalSelectedOptions}
              setPromo={setPromo}
              setPromoObj={setPromoObj}
              cancel={cancel}
              setSelectedImageIndex={setSelectedImageIndex}
              isModalOpen={isModalOpen}
              handleCheckboxChange={handleCheckboxChange}
              formik={formik}
              handleOptionalChange={handleOptionalChange}
              info={info}
              handleDeliveryOptionChange={handleDeliveryOptionChange}
              promoObj={promoObj}
              promoCodeData={promoCodeData}
              promoValue={promoValue}
              setPromoValue={setPromoValue}
              handlePromoCode={handlePromoCode}
              error={error}
              promo={promo}
              message={message}
              totalPrice={totalPrice}
              setTotalPrice={setTotalPrice}
              userId={userId}
              setUserId={setUserId}
              note={note}
              change={change}
              setChange={setChange}
              setSuccess={setSuccess}
              getOffersById={getOffersById}
            />
          </div>
        )}

        {success && <SuccessInfo message={message} />}
        {offersById[0]?.offers?.length > 1 ? (
          <Modal
            offersById={offersById}
            showModal={deleteModal}
            info={`${offerInformation} siparişini iptal etmek istiyor musunuz? `}
            setShowModal={setDeleteModal}
            handleDelete={handleOfferDelete}
          />
        ) : (
          <Modal
            showModal={deleteModal}
            info="Son Ürünü iptal ederseniz siparişiniz komple iptal olacaktır."
            setShowModal={setDeleteModal}
            handleDelete={handleCancelOrder}
          />
        )}
        <InfoModal
          showModal={confirmationModal}
          info="Bu işlem seçili olmadan işleme devam edemiyoruz, dilerseniz ayakkabınızın tüm işlemlerini iptal edebilirsiniz."
          setShowModal={setConfirmationModal}
          handleDelete={handleOfferDelete}
        />
      </section>
    </>
  );
}

export default OrderConfirm;
