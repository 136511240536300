import React, { useContext, useEffect, useState } from "react";
import { getAllServiceCurrentCategories } from "../../../library/network/requests/serviceCategory";
import AddressForm from "../../app-layouts/AddressForm";
import Sally from "../../../utils/img/icons/Saly-1.svg";
import "react-datetime/css/react-datetime.css";
import {
  getUserAddress,
  getUserById,
} from "../../../library/network/requests/webusers";
import ServiceForm from "../../app-layouts/ServiceForm";
import NewAddress from "../../app-layouts/NewAddress";
import OrderPreview from "../../app-layouts/OrderPreview";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { addOrders } from "../../../library/network/requests/orders";
import { LoadingContext } from "../../../contexts/LoadingContext";
import SuccessInfo from "../../app-layouts/SuccessInfo";
import moment from "moment";
import "moment/locale/tr";
import OrderPickupRange from "../../app-layouts/OrderPickupRange";
import { storageHelper } from "../../../utils/helpers/StorageHelper";
import baseService from "../../../library/network/services/BaseService";
import Loader from "../../app-layouts/Loader";
import { Helmet } from "react-helmet-async";
import handleButtonClicks from "../../../utils/helpers/handleButtonClicks";
function CreateOrder() {
  const location = useLocation();
  const preSelected = location?.state?.preSelectedServices;
  const [services, setServices] = useState([]);
  const [note, setNote] = useState("");
  const [isClicked, setIsClicked] = useState(false);
  const [invoice, setInvoice] = useState(false);
  const [date, setDate] = useState("");
  const [selectedNeighborhood, setSelectedNeighborhood] = useState("");
  const [address, setAddress] = useState([]);
  const [deliveryPrice, setDeliveryPrice] = useState(0);
  const [hours, setHours] = useState([]);
  const [showBagQuantity, setShowBagQuantity] = useState(false);
  const [selectedAddressObj, setSelectedAddressObj] = useState(null);
  const [promoValue, setPromoValue] = useState("");
  const [mahalle, setMahalle] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [adressResponse, setAddressResponse] = useState([]);
  const [districtData, setDistrictData] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [promoObj, setPromoObj] = useState(null);
  const [selectedInvoiceObj, setSelectedInvoiceObj] = useState(null);
  const [iframe_token, setIframeToken] = useState("");
  const [selectedAddress, setSelectedAddress] = useState("");
  const [selectedCredit, setSelectedCredit] = useState("");
  const [shoeQuantity, setShoeQuantity] = useState(null);
  const [bagQuantity, setBagQuantity] = useState(null);
  const [promo, setPromo] = useState("");
  const [pickupDate, setPickupDate] = useState(null);
  const [pickupTimeRange, setPickupTimeRange] = useState("");
  const userId = storageHelper.getStoreWithDecryption("userId");
  const [errorMessage, setErrorMessage] = useState("");
  const [policy, setPolicy] = useState(false);
  const [terms, setTerms] = useState(false);
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [orderNum, setOrderNum] = useState("");
  const deliveryService = async () => baseService.get("deliveryhours");
  const [aydınlatma, setAydinlatma] = useState(false);
  const [acikRiza, setAcikRiza] = useState(false);
  const [showQuantity, setShowQuantity] = useState(false);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [userData, setUserData] = useState({});
  const [campaignDiscount, setCampaignDiscount] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const campaignDiscountService = async () =>
    baseService.get("campaigndiscount/current");
  const paymentService = async (data) =>
    baseService.post("payment/reserv", data);
  const getUser = async () => {
    try {
      getUserById(userId).then((res) => {
        setUserData(res?.data);
      });
    } catch (error) {
      console.log(error);
    }
  };
  const getCampainDiscount = async () => {
    try {
      const res = await campaignDiscountService();
      setCampaignDiscount(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleInputChange = (setter) => (e) => {
    const value = e.target.value;

    // Ensure only positive integers
    if (value === "" || (/^\d+$/.test(value) && parseInt(value, 10) >= 0)) {
      setter(value === "" ? 0 : parseInt(value, 10)); // Convert to number or set to 0
    }
  };

  const cityService = async () => baseService.get("cities");
  const deliveryPriceService = async () => baseService.get("deliveryprice");
  const getCities = async () => {
    const res = await cityService();
    setCityData(res.data);
  };
  const getDeliveryPrice = async () => {
    setIsLoading(true);
    const res = await deliveryPriceService();
    setDeliveryPrice(res.data);
    setIsLoading(false);
  };
  const handleInvoiceClick = () => {
    setInvoice(!invoice);
  };
  const getDistricts = async (il) => {
    const filteredData = cityData.filter((item) => item.city === il);
    setDistrictData(filteredData);
  };
  const getNeighborhood = (il) => {
    const filteredData = districtData[0].district.filter(
      (item) => item.name === il
    );
    setMahalle(filteredData);
  };

  var valid = function (current) {
    var today = moment();
    var tomorrow = today.clone().add(1, "day");

    if (current.day() === 0) {
      return false;
    }

    var excludedRanges = [
      ["2024-04-10", "2024-04-11"],
      ["2024-06-16", "2024-06-19"],
    ];

    for (var i = 0; i < excludedRanges.length; i++) {
      if (
        current.isBetween(
          excludedRanges[i][0],
          excludedRanges[i][1],
          null,
          "[]"
        )
      ) {
        return false;
      }
    }

    return current.isSameOrAfter(today);
  };

  const getAddress = async () => {
    try {
      setIsLoading(true);
      await getUserAddress(userId).then((res) => {
        setAddress(res?.data);
      });

      setIsLoading(false);
    } catch (error) {
      setAddress([]);
    }
  };

  const getHours = async () => {
    try {
      const res = await deliveryService();

      const filtered = res?.data?.sort((a, b) =>
        a.startTime.localeCompare(b.startTime)
      );
      setHours(filtered);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    generateUniqueOrderID();
    getAllServiceCurrentCategories().then((res) => setServices(res.data));
    getAddress();

    getCities();
    getUser();
    getHours();
    getCampainDiscount();
    getDeliveryPrice();
  }, []);
  useEffect(() => {
    if (preSelected) {
      setSelectedServices(preSelected);
      const ayakkabi = services.find(
        (item) => item.name === "Ayakkabı Temizleme"
      );
      const canta = services.find((item) => item.name === "Çanta Temizleme");

      const ayakkabiService = preSelected.find((item) => item === ayakkabi?.id);
      const cantaService = preSelected.find((item) => item === canta?.id);

      if (ayakkabiService !== undefined) setShowQuantity(true);
      if (cantaService !== undefined) setShowBagQuantity(true);
    }
  }, [services]);
  const navigate = useNavigate();
  const handleServiceChange = (event) => {
    const serviceId = event.target.value;
    const ayakkabi = services.find(
      (item) => item.name === "Ayakkabı Temizleme"
    );
    const cantaa = services.find((item) => item.name === "Çanta Temizleme");

    const selectedService = services.find((item) => item.id === serviceId);

    setSelectedServices((prevSelectedServices) => {
      if (prevSelectedServices.includes(serviceId)) {
        return prevSelectedServices.filter((id) => id !== serviceId);
      } else {
        return [...prevSelectedServices, serviceId];
      }
    });
    if (event.target.checked) {
      if (selectedService?.id === ayakkabi?.id) {
        setShowQuantity(true);
      }
      if (selectedService?.id === cantaa?.id) {
        setShowBagQuantity(true);
      }
    }
    if (!event.target.checked) {
      if (selectedService?.id === ayakkabi?.id) {
        setShowQuantity(false);
      }
      if (selectedService?.id === cantaa?.id) {
        setShowBagQuantity(false);
      }
    }
  };

  const handleAddressChange = (event) => {
    const selectedAddressObjj = address.find(
      (item) => item._id === event.target.value
    );
    setSelectedAddress(event.target.value);
    setDate("");
    setPickupDate("");
    setPickupTimeRange("");
    setSelectedAddressObj(selectedAddressObjj);
  };
  const handleNewAddressChange = (address) => {
    setSelectedAddress(address._id);
    setDate("");
    setPickupDate("");
    setPickupTimeRange("");
    setSelectedAddressObj(address);
  };

  const calculateTotalAmount = () => {
    let price = selectedAddressObj?.neighborhood?.isCourier
      ? deliveryPrice[0]?.couirierPrice.toFixed(2) * 100
      : deliveryPrice[0]?.deliveryPrice.toFixed(2) * 100;
    return price;
  };
  const generateUserBasketData = () => {
    const userBasketData = selectedAddressObj?.neighborhood?.isCourier
      ? ["Kurye Hizmeti", `${deliveryPrice[0]?.couirierPrice.toFixed(2)}`]
      : ["Kurye Hizmeti", `${deliveryPrice[0]?.deliveryPrice.toFixed(2)}`];
    const basket = JSON.stringify(userBasketData);

    return basket;
  };
  const generateUniqueOrderID = () => {
    let randomPart = Math.floor(Math.random() * 90000) + 1000;

    const timestamp = new Date().getTime();

    const millisecondPart = timestamp.toString().slice(-4);

    const orderNumber = `${randomPart}${millisecondPart}`;
    setOrderNum(orderNumber);
  };
  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      const errors = {};

      if (selectedServices.length === 0) {
        setErrorMessage("Hizmet seçimi gereklidir.");
        return;
      }
      if (!selectedAddress) {
        setErrorMessage("Adres seçimi gereklidir.");
        return;
      }

      if (!terms) {
        setErrorMessage("Lütfen sözleşmeyi okuduktan sonra kabul ediniz.");
        return;
      }
      if (!policy) {
        setErrorMessage("Lütfen sözleşmeyi okuduktan sonra kabul ediniz.");
        return;
      }
      if (selectedAddressObj?.neighborhood?.isCourier && !pickupDate) {
        setErrorMessage("Teslim tarihi seçimi gereklidir.");
        return;
      }
      if (selectedAddressObj?.neighborhood?.isCourier && !pickupTimeRange) {
        setErrorMessage("Teslim zaman aralığı seçimi gereklidir.");
        return;
      }

      const model = {
        note: note,
        orderNumber: orderNum,
        webUserID: userId,
        categories: selectedServices,
        address: selectedAddressObj || "",
        campaignDiscount: selectedCampaign?._id || "",
        cardID: selectedCredit,
        invoice: selectedInvoiceObj,
        pickupDate: pickupDate,
        pickupTimeRange: pickupTimeRange,
        promo: promo,
        shoesQuantity: Number(shoeQuantity),
        bagsQuantity: Number(bagQuantity),
      };

      window.dataLayer.push({
        ecommerce: null,
      });
      const servicename = selectedServices.map((item) => {
        const service = services.find((service) => service.id === item);
        return service.name;
      });
      const price = calculateTotalAmount() / 100;
      window.dataLayer.push({
        event: "add_shipping_info",
        ecommerce: {
          currency: "TRY",
          value: price,
          coupon: promoObj?.title || "",
          shipping_tier: "Sürat Kargo",
          items: [
            {
              promotion_text: selectedCampaign?.title || "",
              item_name: servicename.join(", "),
              coupon: promoObj?.title || "",
              discount: 0,
              index: 0,
              location_id: "Online Lostra",
              price: price,
              quantity: Number(shoeQuantity) + Number(bagQuantity),
            },
          ],
        },
      });

      const response = await addOrders(userId, model);
      if (response.status === 200) {
        setIsLoading(false);
        navigate(
          `/siparis-olusturuldu/${orderNum}/thank-you?type=${
            selectedAddressObj?.neighborhood?.isCourier ?? false
          }`
        );
      }

      setErrorMessage("");
    } catch (error) {
      console.log(error);
    }
  };
  if (isLoading) return <Loader />;

  return (
    <>
      <Helmet>
        <title>{`Sipariş Oluştur - Online Lostra`}</title>
        <meta name="description" content="Lostra Web" />
      </Helmet>
      <section className="page-section left">
        <div className=" order-container-form order-form">
          <div className="container">
            <div className="row">
              <div className="col-xxl-7 col-xl-8 col-lg-7">
                <div className="title left">
                  <h1>Sipariş Oluştur</h1>
                </div>
                <h3>Hizmet Seçimi</h3>
                <small>Birden fazla seçim yapabilirsiniz</small>
                <img style={{ display: "none" }} src={Sally} />

                <div className=" web service-selection">
                  {services && (
                    <ServiceForm
                      services={services}
                      selectedServices={selectedServices}
                      handleServiceChange={handleServiceChange}
                    />
                  )}
                </div>
                <div className="service-selection-main service-selection">
                  <div
                    className="mobile"
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      flexWrap: "wrap",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        flexWrap: "wrap",
                        flexDirection: "row",
                        alignItems: "top",
                      }}
                    >
                      {services.map((service) => {
                        return (
                          <label className="slider-label" key={service.id}>
                            <input
                              style={{
                                appearance: "none",
                                WebkitAppearance: "none",
                                MozAppearance: "none",
                                outline: "none",
                                border: "none",
                                backgroundColor: "transparent",
                              }}
                              name="services"
                              value={service.id}
                              checked={selectedServices.includes(service.id)}
                              onChange={handleServiceChange}
                              type="checkbox"
                              id={service.icon}
                            />
                            <div className="card">
                              <span className={service?.icon}></span>
                            </div>
                            <span className="card-title">
                              <p
                                style={{
                                  margin: "5px 0",
                                  fontSize: "14px",
                                  textAlign: "center",
                                  color: "#6F767E",
                                }}
                              >
                                {service?.name === "Çanta Temizleme" ? (
                                  <>
                                    Çanta <br /> Tamir&Bakım
                                  </>
                                ) : service?.name === "Ayakkabı Temizleme" ? (
                                  <>
                                    Ayakkabı <br /> Tamir&Bakım
                                  </>
                                ) : (
                                  <>{service?.name}</>
                                )}
                              </p>
                            </span>
                            {selectedServices.includes(service.id) ? (
                              <p className="plus-sign active">-</p>
                            ) : (
                              <p className="plus-sign">+</p>
                            )}
                          </label>
                        );
                      })}
                    </div>
                  </div>
                </div>
                {showQuantity && (
                  <div className="my-4">
                    {" "}
                    <h3 className="my-4">
                      Ayakkabı Temizleme Hizmeti (Lostra)
                    </h3>
                    <p className=" order-information">
                      Net fiyat bilgisi ayakkabılarınız bizlere ulaştıktan sonra
                      belirlenmektedir. Ayakkabılarınız incelenir ve gerekli
                      işlemler ‘’İnceleme’’ adımında onayınıza sunulur. Ön fiyat
                      bilgisi edinmek için{" "}
                      <span>
                        <Link
                          onClick={() => {
                            handleButtonClicks(
                              "fiyatlarimiz",
                              "Fiyatlar",
                              "Sipariş Oluştur"
                            );
                          }}
                          target="_blank"
                          to={`/fiyatlarimiz/`}
                        >
                          ‘’Fiyatlar’’
                        </Link>
                      </span>{" "}
                      sayfasını ziyaret edebilirsiniz.
                    </p>
                    <input
                      value={shoeQuantity}
                      onChange={handleInputChange(setShoeQuantity)}
                      inputMode="numeric"
                      // type="number"
                      placeholder="Kaç çift ayakkabı göndereceğinizi giriniz"
                    />
                  </div>
                )}
                {showBagQuantity && (
                  <div className="my-5">
                    {" "}
                    <h3 className="my-4">Çanta Temizleme Hizmeti</h3>
                    <p className=" order-information">
                      Net fiyat bilgisi ayakkabılarınız bizlere ulaştıktan sonra
                      belirlenmektedir. Çantalarınız incelenir ve gerekli
                      işlemler ‘’İnceleme’’ adımında onayınıza sunulur. Ön fiyat
                      bilgisi edinmek için{" "}
                      <span>
                        <Link
                          onClick={() => {
                            handleButtonClicks(
                              "fiyatlarimiz",
                              "Fiyatlar",
                              "Sipariş Oluştur"
                            );
                          }}
                          target="_blank"
                          to={`/fiyatlarimiz/`}
                        >
                          ‘’Fiyatlar’’
                        </Link>
                      </span>{" "}
                      sayfasını ziyaret edebilirsiniz.
                    </p>
                    <input
                      value={bagQuantity}
                      onChange={handleInputChange(setBagQuantity)}
                      inputMode="numeric"
                      placeholder="Kaç çanta göndereceğinizi giriniz"
                    />
                  </div>
                )}

                <AddressForm
                  address={address}
                  selectedAddress={selectedAddress}
                  setIsClicked={setIsClicked}
                  handleAddressChange={handleAddressChange}
                />
                {isClicked && (
                  <NewAddress
                    index={1}
                    setAddressResponse={setAddressResponse}
                    handleNewAddressChange={handleNewAddressChange}
                    setSuccess={setSuccess}
                    setMessage={setMessage}
                    setIsClicked={setIsClicked}
                    getAddress={getAddress}
                    setCityData={setCityData}
                    cityData={cityData}
                    getCities={getCities}
                    getDistricts={getDistricts}
                    getNeighborhood={getNeighborhood}
                    mahalle={mahalle}
                    setMahalle={setMahalle}
                    districtData={districtData}
                    setDistrictData={setDistrictData}
                    selectedNeighborhood={selectedNeighborhood}
                    setSelectedNeighborhood={setSelectedNeighborhood}
                  />
                )}
                {selectedAddress.length > 0 && (
                  <>
                    <div
                      className={
                        !selectedAddressObj?.neighborhood?.isCourier
                          ? "delivery active"
                          : "delivery"
                      }
                    >
                      <h3>Kargo Bilgileri</h3>
                      <p className=" order-information">
                        Ayakkabılarınızı bize kargo ile göndererek hizmetimizden
                        faydalanabilirsiniz.
                      </p>
                      <h6 style={{ color: "#3e57fe" }}>ANLAŞMALI KODUMUZ</h6>
                      <p>
                        <strong>1381312520 </strong>{" "}
                        <span style={{ color: "black" }}>(Sürat Kargo)</span>
                      </p>
                      <div className="order-information">
                        Ayakkabılarınızı ağzı bağlı bir poşet içerisinde
                        ücretsiz olarak teslim edebilirsiniz, özel olarak
                        paketlenip size teslim edilecektir.
                      </div>
                    </div>
                    <div
                      className={
                        selectedAddressObj?.neighborhood?.isCourier
                          ? "delivery active"
                          : "delivery"
                      }
                    >
                      <OrderPickupRange
                        pickupDate={pickupDate}
                        valid={valid}
                        setPickupDate={setPickupDate}
                        pickupTimeRange={pickupTimeRange}
                        setPickupTimeRange={setPickupTimeRange}
                        hours={hours}
                        setDate={setDate}
                      />
                    </div>
                  </>
                )}
                <p className="order-information my-2">
                  İşlem hizmet bedeli ürünleriniz teslim alınıp ilk incelemesi
                  gerçekleştirildikten sonra işlem onayınıza istinaden tahsil
                  edilecektir.
                </p>
              </div>
              <div className="col-xxl-5 col-xl-4 col-lg-5">
                <OrderPreview
                  setPromoObj={setPromoObj}
                  formErrorMessage={errorMessage}
                  selectedAddressObj={selectedAddressObj}
                  setSelectedCampaign={setSelectedCampaign}
                  selectedCampaign={selectedCampaign}
                  campaignDiscount={campaignDiscount}
                  setCampaignDiscount={setCampaignDiscount}
                  selectedInvoiceObj={selectedInvoiceObj}
                  setSelectedInvoiceObj={setSelectedInvoiceObj}
                  getUser={getUser}
                  userData={userData}
                  setUserData={setUserData}
                  setInvoice={setInvoice}
                  invoice={invoice}
                  handleInvoiceClick={handleInvoiceClick}
                  setTerms={setTerms}
                  terms={terms}
                  setPolicy={setPolicy}
                  policy={policy}
                  acikRiza={acikRiza}
                  setAydinlatma={setAydinlatma}
                  aydınlatma={aydınlatma}
                  setAcikRiza={setAcikRiza}
                  userId={userId}
                  promo={promo}
                  setPromoValue={setPromoValue}
                  setPromo={setPromo}
                  promoValue={promoValue}
                  setNote={setNote}
                  time={pickupTimeRange}
                  address={selectedAddressObj}
                  date={date}
                  handleSubmit={handleSubmit}
                  deliveryPrice={deliveryPrice}
                />
              </div>
            </div>
          </div>
        </div>

        {success && <SuccessInfo message={message} />}
      </section>
    </>
  );
}

export default CreateOrder;
