import React, { useEffect, useState } from "react";
import Footer from "../../components/app-layouts/Footer";
import Header from "../../components/app-layouts/Header";
import ScrollToTop from "../../utils/helpers/ScrollToTop";
import AppViews from "../../views/AppViews";
import Whatsapp from "../../utils/img/whatsapp.svg";
import { getAllServiceCurrentCategories } from "../../library/network/requests/serviceCategory";
import { storageHelper } from "../../utils/helpers/StorageHelper";
import baseService from "../../library/network/services/BaseService";
import { useNavigate } from "react-router-dom";
import UpperFooter from "../../components/app-layouts/UpperFooter";
import Banner from "../../components/app-layouts/Banner";
import CouirerBanner from "../../components/app-layouts/CouirerBanner";
function AppLayout() {
  const [id, setId] = useState("");
  const [turnOff, setTurnOff] = useState(true);
  const [turnOffCourier, setTurnOffCourier] = useState(true);
  const navigate = useNavigate();
  const userService = async (id) => baseService.get("webusers/" + id);
  const getData = async () => {
    getAllServiceCurrentCategories().then((res) => setId(res.data[0]?.id));
  };
  const userId = storageHelper.getStoreWithDecryption("userId");
  const getUserData = async () => {
    if (userId) {
      await userService(userId).then((res) => {
        if (res.data.phone === "" || res.data.password === "") {
          navigate("/hesap/profil");
        }
      });
    } else return;
  };

  useEffect(() => {
    getData();
    getUserData();
  }, []);
  return (
    <div className="app-container">
      <ScrollToTop />
      {turnOff && <Banner setTurnOff={setTurnOff} />}
      {/* {turnOffCourier && <CouirerBanner setTurnOff={setTurnOffCourier} />} */}
      <Header />
      <AppViews />
      <div className="whatsapp-container">
        <a
          href="https://wa.me/908505331070"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Whatsapp} alt="whatsapp" className="whatsapp" />
          <div className="whatsapp-message">
            <span>
              <strong>Whatsapp</strong>
            </span>{" "}
            <br />
            <span>Canlı Destek</span>
          </div>
        </a>
      </div>
      <UpperFooter />

      <Footer id={id} />
    </div>
  );
}

export default AppLayout;
