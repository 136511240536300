import React, { useContext, useEffect, useRef } from "react";
import Addresses from "../../app-layouts/Addresses";
import NewAddress from "../../app-layouts/NewAddress";
import {
  getEcommerceAddress,
  getUserAddress,
  getUserById,
} from "../../../library/network/requests/webusers";
import AddressForm from "../../app-layouts/AddressForm";
import baseService from "../../../library/network/services/BaseService";
import { storageHelper } from "../../../utils/helpers/StorageHelper";
import SideNavMenu from "../Checkout/SideNavMenu";
import { CartFavoritesContext } from "../../../contexts/CartFavoritesContext";
import NonRegistered from "./NonRegistered";
import axios from "axios";
import RegisteredUsers from "./RegisteredUsers";
import { useNavigate } from "react-router-dom";
import SuccessInfo from "../../app-layouts/SuccessInfo";
function Payment() {
  const { cart, deliveryPrices } = useContext(CartFavoritesContext);
  const addOrderService = async (values) =>
    baseService.post("ecommerceorders", values);
  const userId = storageHelper.getStoreWithDecryption("userId");
  const [addressResponse, setAddressResponse] = React.useState([]);
  const [selectedNeighborhood, setSelectedNeighborhood] = React.useState("");
  const [cityData, setCityData] = React.useState([]);
  const [districtData, setDistrictData] = React.useState([]);
  const [mahalle, setMahalle] = React.useState([]);
  const [success, setSuccess] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const navigate = useNavigate();
  const [orderNum, setOrderNum] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [iframeToken, setIframeToken] = React.useState("");
  const [address, setAddress] = React.useState([]);
  const [selectedAddress, setSelectedAddress] = React.useState("");
  const [isClicked, setIsClicked] = React.useState(false);
  const [addressError, setAddressError] = React.useState("");
  const [addressObject, setAddressObject] = React.useState({});
  const [userData, setUserData] = React.useState({});
  const [showMessage, setShowMessage] = React.useState(false);
  const paymentService = async (data) =>
    baseService.post("payment/reserv", data);
  const formikRef = useRef();
  const addEcommerceService = async (userId, values) =>
    baseService.post("webusers/ecommerceaddress" + "/" + userId, values);
  const handleFormSubmit = async () => {
    const orderNum = generateUniqueOrderID();
    if (userId) {
      if (!selectedAddress) {
        setAddressError("Önce adres seçiniz."); // Set error message if address is not selected
        return;
      }

      setAddressError(""); // Clear error message if address is selected
      const addressObj = address?.filter(
        (item) => item?._id === selectedAddress
      );

      const paymentData = {
        email: userData?.email || "",
        payment_amount: Math.trunc(getTotalPrice() * 100),
        merchant_oid: orderNum,
        user_name: `${userData?.firstname} ${userData.lastname} ` || "",
        user_address: addressObj[0]?.address || "",
        user_phone: userData.phone || "",
        merchant_fail_url: `https://onlinelostra.com/payment-failed`,
        merchant_ok_url: `https://onlinelostra.com/siparis-olusturuldu/magaza/${orderNum}/thank-you?type=true`, // Replace with your frontend success URL
        user_basket: generateCartData(),
        user_ip: "127.0.0.1",
      };
      const response = await paymentService({
        paymentData: paymentData,
      });
      if (response.data.status === "success") {
        setIframeToken(response.data.token);

        const data = {
          OrderDate: new Date(),
          discount: 0,
          address: addressObj,
          totalPrice: getTotalPrice(),
          products: cart,
          isConfirmed: false,
          webUserId: userId,
          eCommerceOrderNumber: orderNum,
        };
        const res = await addOrderService(data);
        const items = cart.map((item, index) => ({
          item_name: item.name,
          price: item.price,
          quantity: item.quantity,
          promotion_text: "",
          coupon: "",
          discount: 0, // Assuming discount is 0, update this as needed
          index: index,
          location_id: "Online Lostra",
        }));
        const purchaseData = {
          event: "purchase",
          ecommerce: {
            transaction_id: orderNum,
            value: getTotalPrice(),
            tax: getTotalPrice() * 0.2,
            shipping: 0,
            currency: "TRY",
            coupon: "",
            items: items,
          },
        };

        if (res.status === 201) {
          setIsLoading(false);
          navigate("/payment", {
            state: {
              token: response.data.token,
              purchaseData: purchaseData,
              orderNum: orderNum,
              isCourier: false,
            },
          });
        }
      }
    } else {
      if (formikRef.current) {
        formikRef.current.handleSubmit();
        try {
          const paymentData = {
            email: formikRef?.current?.values?.email || "",
            payment_amount: Math.trunc(getTotalPrice() * 100),
            merchant_oid: orderNum,
            user_name:
              `${formikRef?.current?.values?.firstname} ${formikRef?.current?.values?.lastname} ` ||
              "",
            user_address: formikRef?.current?.values?.address || "",
            user_phone: formikRef?.current?.values?.phone || "",
            merchant_fail_url: `https://onlinelostra.com/payment-failed`,
            merchant_ok_url: `https://onlinelostra.com/siparis-olusturuldu/magaza/${orderNum}/thank-you?type=true`, // Replace with your frontend success URL
            user_basket: generateCartData(),
            user_ip: "127.0.0.1",
          };
          const response = await paymentService({
            paymentData: paymentData,
          });
          setIframeToken(response.data.token);
          if (response.data.status === "success") {
            const data = {
              OrderDate: new Date(),
              discount: 0,
              address: [
                {
                  address: formikRef?.current?.values?.address || "",
                  city: formikRef?.current?.values?.city || "",
                  district: formikRef?.current?.values?.district || "",
                  neighborhood: formikRef?.current?.values?.neighborhood || "",
                  title: formikRef?.current?.values?.title || "",
                },
              ],
              totalPrice: getTotalPrice(),
              products: cart,
              isConfirmed: false,
              guestInfo: {
                email: formikRef?.current?.values?.email || "",
                lastname: formikRef?.current?.values?.lastname || "",
                firstname: formikRef?.current?.values?.firstname || "",
                phone: formikRef?.current?.values?.phone || "",
              },
              eCommerceOrderNumber: orderNum,
            };
            const res = await addOrderService(data);
            const items = cart.map((item, index) => ({
              item_name: item.name,
              price: item.price,
              quantity: item.quantity,
              promotion_text: "",
              coupon: "",
              discount: 0,
              index: index,
              location_id: "Online Lostra",
            }));
            const purchaseData = {
              event: "purchase",
              ecommerce: {
                transaction_id: orderNum,
                value: getTotalPrice(),
                tax: getTotalPrice() * 0.2,
                shipping: 0,
                currency: "TRY",
                coupon: "",
                items: items,
              },
            };
            if (res.status === 201) {
              setIsLoading(false);
              navigate("/payment", {
                state: {
                  token: response.data.token,
                  purchaseData: purchaseData,
                  orderNum: orderNum,
                  isCourier: false,
                },
              });
            }
          }
        } catch (error) {
          setAddress([]);
        }
      }
    }
  };
  const getDistricts = async (il) => {
    const filteredData = cityData.filter((item) => item.name === il);
    setDistrictData(filteredData[0]);
  };
  const getUser = async () => {
    try {
      getUserById(userId).then((res) => {
        setUserData(res?.data);
      });
    } catch (error) {
      console.log(error);
    }
  };
  const generateCartData = () => {
    const formattedCartItems = cart.map((item) => [
      item.name,
      item.price.toFixed(2),
      item.size,
      item.color,
      item.quantity,
    ]);

    const basket = JSON.stringify(formattedCartItems);

    return basket;
  };
  const generateUniqueOrderID = () => {
    let randomPart = Math.floor(Math.random() * 900000) + 100000;

    const timestamp = new Date().getTime();

    const millisecondPart = timestamp.toString().slice(-4);

    const orderNumber = `${randomPart}${millisecondPart}`;

    return orderNumber;
  };

  const getTotalPrice = () => {
    return cart.reduce((total, item) => {
      const uniqueKey = `${item.productId._id}-${item.size}-${item.color}`;
      return deliveryPrices + total + item.productId.price * item.quantity;
    }, 0);
  };
  const getNeighborhood = async (il) => {
    const res = await axios.get(
      `https://turkiyeapi.dev/api/v1/districts/${il}`
    );
    setMahalle(res.data.data);
  };
  useEffect(() => {
    if (userId) {
      getUser();
    }
  }, []);
  const handleAddressChange = (e) => {
    setAddressError("");
    setSelectedAddress(e.target.value);
    const addressData = address.filter((item) => item._id === e.target.value);
    setAddressObject(addressData[0]);
  };
  const handleNewAddressChange = (address) => {
    setAddressError("");
    const addresses = address?.ecommerceAddress;

    setSelectedAddress(addresses[addresses?.length - 1]?._id);
  };

  const getCities = async () => {
    const res = await axios.get("https://turkiyeapi.dev/api/v1/provinces");
    setCityData(res.data.data);
  };
  const getAddress = async () => {
    try {
      setIsLoading(true);

      await getEcommerceAddress(userId).then((res) => {
        setAddress(res?.data);
      });

      setIsLoading(false);
    } catch (error) {
      setAddress([]);
    }
  };
  useEffect(() => {
    if (userId) {
      getAddress();
    }
    getCities();
  }, []);
  return (
    <section className="page-section">
      <div className="container">
        {showMessage && <SuccessInfo message="Adres başarıyla eklendi." />}
        <div
          className="e-commerce-container"
          style={{
            display: "flex",
            width: "100%",
            alignItems: "flex-start",
            gap: "16px",
          }}
        >
          {userId ? (
            <>
              <div
                style={{
                  width: "100%",

                  textAlign: "left",
                }}
                className=" order-container-form order-form"
              >
                <AddressForm
                  address={address}
                  selectedAddress={selectedAddress}
                  setIsClicked={setIsClicked}
                  handleAddressChange={handleAddressChange}
                />
                {isClicked && (
                  <RegisteredUsers
                    setMessage={setShowMessage}
                    setIsClicked={setIsClicked}
                    handleNewAddressChange={handleNewAddressChange}
                    getAddress={getAddress}
                    setAddressResponse={setAddressResponse}
                    cityData={cityData}
                    setError={setAddressError}
                    getDistricts={getDistricts}
                    getNeighborhood={getNeighborhood}
                    mahalle={mahalle}
                    districtData={districtData}
                    setDistrictData={setDistrictData}
                    selectedNeighborhood={selectedNeighborhood}
                    setSelectedNeighborhood={setSelectedNeighborhood}
                  />
                )}
                {addressError && (
                  <div style={{ color: "red", marginTop: "10px" }}>
                    {addressError}
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              {" "}
              <div
                style={{
                  width: "100%",
                  textAlign: "left",
                }}
              >
                <h3 className="contact-form-title">Kişi bilgileri</h3>
                <NonRegistered
                  formikRef={formikRef}
                  setAddressResponse={setAddressResponse}
                  cityData={cityData}
                  getDistricts={getDistricts}
                  getNeighborhood={getNeighborhood}
                  mahalle={mahalle}
                  districtData={districtData}
                  setDistrictData={setDistrictData}
                  selectedNeighborhood={selectedNeighborhood}
                  setSelectedNeighborhood={setSelectedNeighborhood}
                />
              </div>
            </>
          )}
          <SideNavMenu
            onSubmit={handleFormSubmit}
            payment={true}
            getTotalPrice={getTotalPrice}
            route="/odeme"
          />
        </div>
      </div>
    </section>
  );
}

export default Payment;
