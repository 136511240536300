import React, { useState } from "react";
import OrderImages from "./OrderImages";
import logo from "../../../utils/img/icons/delete-red.svg";
import Checkboxes from "./Checkboxes";
import shopppingCart from "../../../utils/img/shopping-bag-add.svg";
import OptionalRadios from "./OptionalRadios";
import RadioButtons from "./RadioButtons";
import OrderConfirmPreview from "../../app-layouts/OrderConfirmPreview";
import cancelImg from "../../../utils/img/icons/cancel.svg";

function OrderForm(props) {
  const {
    selectedExtraProducts,
    setSelectedExtraProducts,
    offersById,
    addCover,
    setAddCover,
    extraProducts,
    firstOrder,
    selectedImageIndex,
    selectedOptions,
    setConfirmationModal,
    setDeleteModal,
    setIsModalOpen,
    setMessage,
    setNote,
    setOfferId,
    campaignDiscount,
    setOfferInformation,
    setOptionalSelectedOptions,
    setPromo,
    setPromoObj,
    cancelledOrders,
    cancel,
    setSelectedImageIndex,
    isModalOpen,
    handleCheckboxChange,
    formik,
    handleOptionalChange,
    info,
    handleDeliveryOptionChange,
    promoObj,
    promoCodeData,
    promoValue,
    setPromoValue,
    targetDate,
    handleCoverChange,
    handlePromoCode,
    imageUrls,
    handleRecover,
    error,
    promo,
    message,
    setTotalPrice,
    totalPrice,
    userId,
    setUserId,
    note,
    change,
    setChange,
    setSuccess,
    getOffersById,
  } = props;
  const [selectedSize, setSelectedSize] = useState({});
  const handleAddButton = (item) => {
    let targetProduct;
    const productSize = selectedSize[item._id] || "";
    if (item.sizes.length > 1) {
      targetProduct = selectedExtraProducts.find(
        (product) => product._id === item._id && product.size === productSize
      );
    } else {
      targetProduct = selectedExtraProducts.find(
        (product) => product._id === item._id
      );
    }
    if (targetProduct) {
      const updatedProducts = selectedExtraProducts.map((product) =>
        product._id === item._id && product.size === targetProduct.size
          ? {
              ...product,
              quantity: product.quantity + 1,
              id: `${item._id}-${productSize}`,
            }
          : product
      );
      setSelectedExtraProducts(updatedProducts);
    } else {
      setSelectedExtraProducts([
        ...selectedExtraProducts,
        {
          ...item,
          quantity: 1,
          id: `${item._id}-${productSize}`, // If the item has multiple sizes, we need to add the size to the id so that we can differentiate between the same item with different sizes
          size: item.sizes.length === 1 ? "one-size" : productSize,
        },
      ]);
    }

    setTotalPrice(totalPrice + Number(item.price));
  };
  const handleRemoveButton = (item) => {
    const targetProductIndex = selectedExtraProducts.findIndex(
      (product) => product._id === item._id
    );
    // const updatedSizes = { ...selectedSize };
    // delete updatedSizes[item._id];
    // setSelectedSize(updatedSizes);
    if (targetProductIndex !== -1) {
      const updatedProducts = [...selectedExtraProducts];

      const targetProduct = { ...updatedProducts[targetProductIndex] };

      if (targetProduct.quantity > 1) {
        targetProduct.quantity -= 1;
        updatedProducts[targetProductIndex] = targetProduct;
        setSelectedExtraProducts(updatedProducts);
      } else {
        updatedProducts.splice(targetProductIndex, 1);
        setSelectedExtraProducts(updatedProducts);
      }

      setTotalPrice(totalPrice - Number(item.price));
    }
  };

  return (
    <>
      <div className=" order-container-form order-form">
        <div className="container">
          <div className="row">
            <div className="col-xxl-7 col-xl-8 col-lg-7">
              <div className="title my-3 left">
                <h1>Sipariş Onay</h1>
              </div>
              <h3 classNam="font-500">
                Sipariş No:{" "}
                <span style={{ fontWeight: "bold" }}>
                  {offersById[0]?.orderId?.orderNumber}
                </span>{" "}
              </h3>
              <small>
                Ayakkabılarınız için yapılması gereken işlemler aşağıdaki
                gibidir:
              </small>
              {offersById &&
                offersById.map((item) => {
                  return (
                    <div
                      className="mt-3 confirm-order-container"
                      key={item?.id}
                    >
                      <div className="d-flex flex-column justify-content-between">
                        <div>
                          {item &&
                            item.offers.map((offer, offerIndex) => (
                              <div
                                className="mb-5 order-offer-container"
                                key={offer?._id}
                              >
                                <img
                                  style={{
                                    position: "absolute",
                                    right: "15px",
                                    cursor: "pointer",
                                  }}
                                  src={cancel}
                                  onClick={() => {
                                    setDeleteModal(true);
                                    setOfferInformation(offer?.name?.name);
                                    setOfferId(offer);
                                  }}
                                  alt="cancel"
                                />
                                <div>
                                  <h3 style={{ margin: "20px 0" }}>
                                    {offer?.name?.name}
                                  </h3>
                                </div>
                                <OrderImages
                                  imageUrls={imageUrls}
                                  offer={offer}
                                  offerIndex={offerIndex}
                                  setIsModalOpen={setIsModalOpen}
                                  setSelectedImageIndex={setSelectedImageIndex}
                                  isModalOpen={isModalOpen}
                                  selectedImageIndex={selectedImageIndex}
                                />
                                {selectedImageIndex && (
                                  <div
                                    style={{
                                      opacity: isModalOpen ? 1 : 0,
                                      backgroundColor: isModalOpen
                                        ? "rgba(0,0,0,0.5)"
                                        : "transparent",
                                      zIndex: isModalOpen ? 1000 : -1,
                                    }}
                                    className="mainImagesOrderOverlay"
                                    onClick={() => setIsModalOpen(false)}
                                  >
                                    <div className="serviceimgcontainer">
                                      <img
                                        className="servicecloseimg"
                                        style={{
                                          backgroundColor: "grey",
                                          padding: "5px",
                                          borderRadius: "30%",
                                          position: "absolute",
                                          top: "5px",
                                          zIndex: "1001",
                                          cursor: "pointer",
                                          right: "5px",
                                          width: "20px",
                                        }}
                                        onClick={() => {
                                          setIsModalOpen(false);
                                          setSelectedImageIndex("");
                                        }}
                                        src={cancelImg}
                                        alt="cancel"
                                      />
                                      <img
                                        className="serviceImage"
                                        style={{
                                          objectFit: "contain",
                                          maxWidth: "400px",
                                          maxHeight: "600px",
                                          transform: isModalOpen
                                            ? "scale(1) translate(0,0)"
                                            : "scale(0.8) translate(0,100px)",
                                          transition:
                                            "transform 0.5s ease-in-out",
                                        }}
                                        src={imageUrls[selectedImageIndex]}
                                        alt={`Offer ${offerIndex + 1} Image ${
                                          selectedImageIndex + 1
                                        }`}
                                        onClick={(e) => e.stopPropagation()}
                                      />
                                    </div>
                                  </div>
                                )}
                                <div>
                                  {offer && (
                                    <>
                                      <Checkboxes
                                        handleCoverChange={handleCoverChange}
                                        totalPrice={totalPrice}
                                        setTotalPrice={setTotalPrice}
                                        addCover={addCover}
                                        setAddCover={setAddCover}
                                        setConfirmationModal={
                                          setConfirmationModal
                                        }
                                        setOfferId={setOfferId}
                                        offer={offer}
                                        formik={formik}
                                        setSelectedImageIndex={
                                          setSelectedImageIndex
                                        }
                                        setIsModalOpen={setIsModalOpen}
                                        handleCheckboxChange={
                                          handleCheckboxChange
                                        }
                                      />
                                    </>
                                  )}

                                  <OptionalRadios
                                    imageUrls={imageUrls}
                                    addCover={addCover}
                                    handleCoverChange={handleCoverChange}
                                    offer={offer}
                                    offerIndex={offerIndex}
                                    handleOptionalChange={handleOptionalChange}
                                    setOptionalSelectedOptions={
                                      setOptionalSelectedOptions
                                    }
                                    setSelectedImageIndex={
                                      setSelectedImageIndex
                                    }
                                    setIsModalOpen={setIsModalOpen}
                                  />

                                  <h5>Teslimat Bilgileri </h5>
                                  <RadioButtons
                                    firstOrder={firstOrder}
                                    selectedOptions={selectedOptions}
                                    info={info}
                                    handleDeliveryOptionChange={
                                      handleDeliveryOptionChange
                                    }
                                    offerIndex={offerIndex}
                                    item={item}
                                    offer={offer}
                                  />
                                </div>
                                <div>
                                  {offer?.notes && (
                                    <p className="small-info order-information mt-5">
                                      **Ürün notu: ({offer?.notes})
                                    </p>
                                  )}
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  );
                })}
              {cancelledOrders && cancelledOrders.length > 0 && (
                <div className="mt-3 confirm-order-container">
                  <div className="d-flex flex-column justify-content-between">
                    <div>
                      <h3 style={{ margin: "20px 0" }}>İptal Edilen Ürünler</h3>
                    </div>
                    <div>
                      {cancelledOrders &&
                        cancelledOrders.map((item) => (
                          <div
                            className="mb-5 order-offer-container"
                            key={item?._id}
                          >
                            <div className="cancelled-product-container">
                              {" "}
                              <div className=" d-flex justify-content-center align-items-start flex-column mt-2">
                                <h3 style={{ margin: "20px 0" }}>
                                  {item?.name?.name}
                                </h3>
                                <p
                                  style={{
                                    fontSize: "16px",
                                  }}
                                >
                                  Bu ürün iptal edilmiştir.
                                </p>{" "}
                              </div>
                              <button
                                type="button"
                                onClick={() => handleRecover(item)}
                                className="product-cancel-btn button-ghost-input "
                              >
                                İptal işlemini geri al.
                              </button>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              )}

              <div className="products-container">
                <h3>Siparişinle Birlikte Satın Alabileceğin Ürünler</h3>
                {extraProducts &&
                  extraProducts.map((item) => {
                    const productsWithSameIdAndSize =
                      selectedExtraProducts.filter(
                        (product) => product._id === item._id
                      );

                    const quantity = productsWithSameIdAndSize.reduce(
                      (total, product) => total + product.quantity,
                      0
                    );

                    return (
                      <div className="product-container" key={item._id}>
                        <div className="img-extraproduct">
                          <img
                            alt="extra-product-img"
                            className="extra-product-img"
                            src={imageUrls[item.mainImages]}
                          />
                        </div>
                        <div className="extra-product-info ">
                          <div>
                            <p className="product-title">{item.title}</p>
                            <p
                              className="product-price"
                              style={{ marginBottom: "0" }}
                            >
                              ₺{Number(item?.price).toFixed(2)}
                            </p>
                          </div>
                          <div className="quantity-controls">
                            {quantity === 0 ? (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "flex-end",
                                  gap: "10px",
                                  flexWrap: "wrap",
                                }}
                                className="product-quantity-containers"
                              >
                                {" "}
                                <button
                                  type="button"
                                  disabled={
                                    item.sizes.length > 1 &&
                                    (!selectedSize[item._id] ||
                                      selectedSize[item._id].length === 0)
                                  }
                                  onClick={() => {
                                    handleAddButton(item);
                                  }}
                                  className="add-product button-primary "
                                >
                                  <img
                                    style={{
                                      width: "20px",
                                      border: "none",
                                    }}
                                    alt="shopping-cart"
                                    src={shopppingCart}
                                  />{" "}
                                  Siparişe Ekle
                                </button>
                                {item.sizes != "one-size" && (
                                  <select
                                    style={{
                                      appearance: "auto",
                                      maxWidth: "160px",
                                      fontSize: "12px",
                                      height: "auto",
                                      padding: "12px 30px 12px   12px",
                                    }}
                                    value={selectedSize[item._id] || ""} // Replace with the selected size state
                                    onChange={(e) => {
                                      setSelectedSize({
                                        ...selectedSize,
                                        [item._id]: e.target.value,
                                      });
                                    }}
                                  >
                                    <option
                                      style={{
                                        display: "none",
                                      }}
                                      value=""
                                    >
                                      Numara seçiniz
                                    </option>
                                    {item.sizes.map((size) => (
                                      <option key={size} value={size}>
                                        {size}
                                      </option>
                                    ))}
                                  </select>
                                )}
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "flex-end",
                                  gap: "10px",
                                  // justifyContent: "center",
                                  flexWrap: "wrap",
                                }}
                              >
                                <div className="product-quantity-container">
                                  {quantity > 1 && (
                                    <button
                                      type="button"
                                      onClick={() => handleRemoveButton(item)}
                                      className="remove-button"
                                    >
                                      -
                                    </button>
                                  )}
                                  {quantity === 1 && (
                                    <img
                                      alt="delete"
                                      onClick={() => handleRemoveButton(item)}
                                      style={{
                                        border: "none",
                                        cursor: "pointer",
                                        width: "20px",
                                        marginRight: "5px",
                                      }}
                                      src={logo}
                                    />
                                  )}
                                  <span className="product-quantity">
                                    {quantity}
                                  </span>
                                  <button
                                    type="button"
                                    onClick={() => handleAddButton(item)}
                                    className="add-button"
                                  >
                                    +
                                  </button>
                                </div>
                                {item.sizes != "one-size" && (
                                  <div>
                                    {" "}
                                    <select
                                      style={{
                                        appearance: "auto",
                                        maxWidth: "150px",
                                        fontSize: "12px",
                                        padding: "12px 30px 12px   12px",
                                      }}
                                      value={selectedSize[item._id] || ""} // Replace with the selected size state
                                      onChange={(e) => {
                                        setSelectedSize({
                                          ...selectedSize,
                                          [item._id]: e.target.value,
                                        });
                                      }}
                                    >
                                      <option
                                        style={{
                                          display: "none",
                                        }}
                                        value=""
                                      >
                                        Numara seçiniz
                                      </option>
                                      {item.sizes.map((size) => (
                                        <option key={size} value={size}>
                                          {size}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>

            <OrderConfirmPreview
              targetDate={targetDate}
              promoObj={promoObj}
              campaignDiscount={campaignDiscount}
              setPromoCodeData={props.setPromoCodeData}
              promoCodeData={promoCodeData}
              setPromoObj={setPromoObj}
              promoValue={promoValue}
              setPromoValue={setPromoValue}
              handlePromoCode={handlePromoCode}
              error={error}
              promo={promo}
              message={message}
              setMessage={setMessage}
              setPromo={setPromo}
              userId={userId}
              setUserId={setUserId}
              discount={promoCodeData?.discount}
              note={note}
              setNote={setNote}
              discountType={promoCodeData?.discountType}
              title={promoCodeData?.title}
              offers={offersById}
              totalPrice={totalPrice}
              change={change}
              setChange={setChange}
              setSuccess={setSuccess}
              getOffersById={getOffersById}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default OrderForm;
